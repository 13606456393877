import React from "react";
import Ylines from "../visualization/Ylines/Ylines";
import './BarChart.scss';

const BarChart = ({ data, yLabels, prefix, positiveNegative, max = 100, mirror, numberOnTop, mode = 'score' }) => data && (
    <div className="BarChart">
        <div className="BarChart__chart">
            <Ylines
                max={max}
                min={mirror ? -max : 0}
                step={max / 5}
                positiveNegative={positiveNegative}
                yLabelTop={yLabels && yLabels[0]}
                yLabelBottom={yLabels && yLabels[1] && yLabels[1]}
            />
            <div className="BarChart__wrapper">
                {data.map(column => (
                    <div
                        className="BarChart__column"
                        style={{
                            width: `${1 / data.length * 100}%`,
                        }}
                    >
                        <div className="BarChart__columnWrapper">
                            {column.data.map(bar => (
                                <div
                                    className="BarChart__bar"
                                    style={{
                                        width: '80%',
                                        height: (!!bar.data && bar.data.length > 0 & mirror) ? '100%' : `${(mode == 'score' ? bar.score : bar.percent) / max * 100}%`,
                                        backgroundColor: bar.colorCode,
                                        transform: mirror && `translateY(${50 - bar?.data?.reduce((prev, curr) => {
                                            return prev + ((mode == 'score' ? curr.score : curr.percent) > 0 ? ((mode == 'score' ? curr.score : curr.percent) / max * 100 / (mirror ? 2 : 1)) : 0)
                                        }, 0)}%)`
                                    }}
                                >
                                    {numberOnTop && (
                                        <div className="BarChart__barScore">
                                            {Math.round(mode == 'score' ? bar.score : bar.percent)}
                                        </div>
                                    )}
                                    {bar?.data?.map((stack, index) => (
                                        <div
                                            className="BarChart__stack"
                                            style={{
                                                height: `${Math.abs(mode == 'score' ? stack.score : stack.percent) / max * 100 / (mirror ? 2 : 1)}%`,
                                                backgroundColor: stack.colorCode,
                                                position: 'relative',
                                                borderRadius: 2,
                                            }}
                                        >
                                            <div
                                                className={`BarChart__tooltip--${index} BarChart__tooltip--${stack.color}`}
                                            >
                                                <div>
                                                    {(mode == 'score' ? Math.round(mirror ? Math.abs(stack.score) : stack.score) : `${Math.abs(stack.percent)}%`)}{prefix} {stack.label}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                        <div className="BarChart__labelWrapper">
                            <span className="BarChart__label">
                                {column.label}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div >
);

export default BarChart;