import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import CheckboxField from "../CheckboxField/CheckboxField";
import './BarChartMode.scss';

const BarChartMode = ({ onChange, isChecked }) => (
    <div className="BarChartMode">
        <Flex gap="1rem" flexWrap="wrap">
            <CheckboxField
                onChange={onChange}
                checked={isChecked}
            />
            <Text color="black" fontWeight="bold" fontSize="small">{"Show percentages"}</Text>
        </Flex>
    </div >
);

export default BarChartMode;