import React, { useEffect, useState } from "react";
import Block from "../../Block/Block";
import Alignment from "../../Alignment/Alignment";
import BarChart from "../../BarChart/BarChart";
import BarChartMode from "../../BarChartMode/BarChartMode";
import Grid from "../../gridSystem/Grid/Grid";
import GridColumn from "../../gridSystem/GridColumn/GridColumn";
import colors from "../../../styles/colors";
import { ReactComponent as Person } from '../../../assets/icons/person.svg';

const TransitionImpact = ({ ready, data, getData, alignment }) => {
    const [on, setOn] = useState({ name: 'Show average', value: 'average' });
    const [mode, setMode] = useState({ value: 'score' });

    useEffect(() => {
        if (ready) getData('average');
    }, [ready]);

    const handleOnChangeAlignment = target => {
        setOn({ ...target, value: target.value || 'average' });
        getData(target.value || 'average');
    }

    const handleOnChangeMode = () => {
        setMode({ value: mode.value == 'percent' ? 'score' : 'percent' });
    }

    const mapLabel = grad => ({
        'major-opportunity': { colorCode: colors["color__" + grad], color: grad, sort: 3 },
        'minor-opportunity': { colorCode: colors["color__" + grad + "-light"], color: grad, sort: 4 },
        'major-risk': { colorCode: colors["color__" + grad + "-dark-2"], color: grad, sort: 2 },
        'minor-risk': { colorCode: colors["color__" + grad + "-dark"], color: grad, sort: 1 },
    });

    const getBarChart = (arr) => (
        <BarChart
            isStacked={true}
            compressed={true}
            prefix=""
            mode={mode.value}
            max={mode.value == 'percent' ? data[on.value]?.horizon.company.maxPercent + 7 : data[on.value]?.horizon.company.max + 3}
            mirror={true}
            positiveNegative={true}
            yLabels={['Opportunity', 'Risk']}
            data={arr.map(item => ({
                ...item,
                data: [
                    ...item.data.map(i => {
                        let grad = on.value === 'average' ? 'grad-2' : alignment[on.value][i.id.toLowerCase()].color;
                        return ({
                            ...i,
                            data: i.data.filter(j =>
                                j.id !== "no-opportunity" &&
                                j.id !== "no-risk" &&
                                j.id !== "dk-opportunity" &&
                                j.id !== "dk-risk"
                            )
                                .map(j => ({ ...j, colorCode: mapLabel(grad)[j.id]?.colorCode, color: mapLabel(grad)[j.id]?.color, label: j.label }))
                                .sort((a, b) => mapLabel(grad)[b.id].sort - mapLabel(grad)[a.id].sort)
                        });
                    })
                ]
            }))}
        />
    );
    return ready && (
        <Block
            color="white"
            title="Transition impact"
        >
            <Alignment
                isSelected={on.name}
                onClick={handleOnChangeAlignment}
                legends={on.value !== 'average' && alignment[on.value]}
                icon={on.value === 'roles' && (props => <Person {...props} />)}
            />
            <BarChartMode
                onChange={handleOnChangeMode}
                isChecked={mode.value == 'percent'}
            />
            <Grid>
                <GridColumn size={12} noPadding>
                    <h4>Company opportunities and risks</h4>
                </GridColumn>
                <GridColumn size={12} noPadding>
                    {(data[on.value]?.horizon?.company?.data.length > 0) && getBarChart(data[on.value].horizon.company.data)}
                </GridColumn>
                <GridColumn size={12} noPadding>
                    <h4>Industry opportunities and risks</h4>
                </GridColumn>
                <GridColumn size={12} noPadding>
                    {(data[on.value]?.horizon?.industry?.data.length > 0) && getBarChart(data[on.value].horizon.industry.data)}
                </GridColumn>
            </Grid>
        </Block >
    )
};

export default TransitionImpact;